import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        for (let element of document.querySelectorAll('[data-auto-select]')) {
            element.addEventListener('change', () => {
                if (!element.selectedIndex) {
                    return;
                }

                const attr = JSON.parse(element.options[element.selectedIndex].getAttribute('data-auto-select-value'));
                if (!attr) {
                    return;
                }

                const found = document.querySelector('#' + attr.input);

                if (!found || !found.tomselect || found.tomselect.getValue() !== '') {
                    return;
                }

                found.tomselect.setValue(attr.value);
            });
        }
    }

    disconnect() {

    }
}